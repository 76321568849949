import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          凯伊云
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about React
        </a>
        <div className='info'>
        备案号：
        <a className="recordation-link" target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/">苏ICP备2023038864号-1</a>
        </div>
      </header>
    </div>
  );
}

export default App;
